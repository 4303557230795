:root {
  --color-primary:#9c27b0;
  --min-height-slider: 400px;
  --min-height-slider-xs: 500px;
  --h0-font-size: 80px;
  --h0-font-size-xs: 50px;
  --h1-font-size: 42px;
  --h1-font-size-xs: 16px;
  --h2-font-size: 12px;
  --h2-font-size-xs: 12px;
  --h3-font-size: 26px;
  --h3-font-size-xs: 12px;
  --h4-font-size:24px;
}

html,body{
  margin: 0;
  background-color: #fff;
  font-family: 'Raleway';
  min-height: 100vh;
  /* font-family: 'Anton', sans-serif;
  font-family: 'Open Sans', sans-serif; */
}

.simula_input_sin_borde{
  margin-left: 8px !important;
  padding: 12px 0 !important;
  height: 40px;
  margin-top: 8px !important;
  font-weight: bold;
}

.vertical-align{
  vertical-align: bottom !important;
}

.border-green{
  border-bottom: #004b21 3px solid;
  padding-bottom: 5px;
}

.blocks{
  margin-bottom: 20px;
}

.blocks .header{
  background-color: #004b21;
  padding: 10px 20px;
  color: #fff;
}

.blocks .body{
  margin-top: 20px;
}

.circle{
  width: 100px;
  height: 100px;
  background-color: #D9D9D9;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.vertical-center-container{
  position: relative;
  height: 100%;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.t-36{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 36px !important;
  line-height: 18px;
}

.t-12{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 14px;
}

.block-footer{
  background-color: #000;
  color:#fff;
}

.title-course-main{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 42px !important;
  line-height: 49px !important;
  margin-bottom: 20px !important;
}

.title-course-p{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.title-main{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 42px !important;
  line-height: 49px;
  text-align: center;
}

.title-main-secondary{
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 38px;
  text-align: center;
  padding: 10px 20%;
}

.p-main{
  font-family: 'Raleway';
  font-style: normal;
  /*font-weight: 700 !important;;*/
  font-size: 12px !important;;
  line-height: 14px;
  text-align: center;
  color: #000000;
}

.cta-title{
  font-size: var(--h1-font-size);
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 42px !important;
  line-height: 49px;
  max-width: 50%;
}


.bg-gray{
  background-color: #9E9C9C;
}

.bg-gray-100{
  background-color: #d1d1d1;
}

.bg-big-black{
    background-color: #000;
}

.title_item2{
  font-size: 16px;
  overflow: hidden;
  background-color: var(--color-primary);
  color: #fff;
}

.title_item3{
  font-size: 14px;
  color: #111;
  font-family: 'Anton', sans-serif;
  margin-bottom: 10px;
}

.title_item4{
  font-size: 12px;
  color: #aaa;
}

.title_item{
  font-size: 16px;
  height: 30px;
  overflow: hidden;
}

.text-max-height{
  height: 58px;
  overflow: hidden;
}

#root{
  min-height: 100vh;
  position: relative;
}

.img-svg{
  width: 400px;
  height: 400px;
}

.img-logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.uppercase{
  text-transform: uppercase;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.cursor-pointer{
  cursor: pointer;
}
.cursor-pointer:hover{
  color: #9c27b0;
}

.cursor-pointer-dark{
  cursor: pointer;
}
.cursor-pointer-dark:hover{
  color: #333;
}

a,
a.link,
a.hover{
  color: #333;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.disconnected{
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border: 2px solid #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.feature{
  margin: 8px;
  padding: 10px;
  background-color: #f2f2f2;
  border:solid 1px #d1d1d1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.feature_gray{
  margin: 0 8px;
  margin-bottom: 2px;
  padding: 10px;
  background-color: #f2f2f2;
  border:solid 1px #d1d1d1;
  -webkit-border-radius: 0 0  4px  4px  ;
  -moz-border-radius: 0 0  4px  4px ;
  border-radius: 0 0  4px  4px ;
}

.feature_invert{
  color: #fff;
  margin: 0 8px ;
  padding: 10px;
  background-color: #222;
  border:solid 1px #111;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}

.text-start{
  text-align: left;
}
.text-primary{
  font-size: 16px;
}

.mt-2{
  margin-top: 20px !important;
}

.p-1{
  padding: 10px !important;
}
.p-2{
  padding: 20px !important;
}
.m-0{
  margin: 0 !important;
}
.mb-05{
  margin-bottom: 5px !important;
}
.mb-1{
  margin-bottom: 10px !important;
}
.mb-2{
  margin-bottom: 20px !important;
}
.rbc-event{
  font-size: 10px;
}
.rbc-event .rbc-event-label{
  font-size: 16px;
  font-weight: bold;
}

.title-secondary{
  /*font-size: 14px !important;*/
  font-weight: bold !important;
  color: #9c27b0;
}

.title-xs{
  /*font-size: 12px !important;*/
  /*font-weight: bold !important;*/
  color: #333;
}

table td,
table th{
  /*font-size: 14px !important;*/
}

.text-gray{
  color: #555;
}

.text-justify{
    text-align: justify;
}

.text-right{
  text-align: right;
}

.text-center{
  text-align: center;
}

.text-start{
  text-align: left;
}

.text-end{
  text-align: right;
}

.chat-textarea{
  margin: 0px;
}

.chat-content{
  max-height: 400px;
  overflow: hidden;
  overflow-y:scroll;
  scrollbar-width: thin;
}

.chat-items{
  padding: 5px;
  background-color: #fff;
  /*margin: 10px !important;*/
  border-bottom: #9c27b0 solid 3px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 10px !important;
  margin-right: 100px !important;
}

.chat-items .autor{
  font-size: 11px !important;
  font-weight: bold !important;
  color: #9c27b0;
  text-transform: capitalize !important;
}
.chat-items .date{
  padding-right: 10px;
  text-align: right;
  font-size: 10px !important;
  color: #999;
  text-transform: capitalize !important;
}

.chat-items .text{
  padding: 5px 10px;
  font-size: 13px !important;
  color: #999;
  text-transform: capitalize !important;
}

.bg-white{
  background-color: #fff;
}
hr{
    border: 2px solid #9c27b0;
    border-radius: 5px;
  }
.upload-content svg{
  font-size: 50px;
}
.upload-content{
  border: dashed 1px #cccccc;
  text-align: center;
  cursor: pointer;
  margin: 8px;
  border-radius: 4px;
  padding: 50px;
}
.upload-content:hover{
  border: solid 1px #9c27b0;
}
.upload-content,
.cam-content{
  position: relative;
}
.upload-content input,
.cam-content input{
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: alpha(opacity=0);
 -moz-opacity: 0;
 -khtml-opacity: 0;
  opacity: 0;
}

.img-fluid{
  width: 100%;
}

.title{
  background-color: #333;
}

.h6{
  font-size: 14px !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

.max-height-80{
  height: 30px;
  overflow: hidden;
}

.max-height-400{
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.simula_input{
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: 16.5px 14px;
  margin: 8px;
}

.ml-1{
  margin-left: 7.5px !important;
}
.mr-1{
  margin-right: 7.5px !important;
}

.ml-2{
  margin-left: 15px !important;
}
.mr-2{
  margin-right: 15px !important;
}

.pr-1{
  padding-right: 7.5px !important;
}
.pl-1{
  padding-left: 7.5px !important;
}

.pr-2{
  padding-right: 15px !important;
}
.pl-2{
  padding-left: 15px !important;
}

.item-gray{
    background-color: #f2f2f2;
}

.row{
  display: flex;
  flex-direction: row;
}
.col{
  flex: 1 1 auto;
  padding: 10px;
}

.col-3{
  width: 25%;
  padding: 1%;
}

.col-4{
  width: 33.3%;
  padding: 1%;
}

.col-6{
  width: 50%;
  padding: 1%;
}

p{
  text-align: justify;
}

.btn2 {
  width: 100%;
  background: #FF4742;
  border: 1px solid #FF4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.btn2:hover,
.btn2:active {
  background-color: initial;
  background-position: 0 0;
  color: #FF4742;
}

.btn2:active {
  opacity: .5;
}

.min-height-80{
  min-height: '85vh';
  margin-bottom:100px;
}

.min-width-10{
  min-width: 10%;
}

.min-width-20{
  min-width: 20%;
}

.min-width-30{
  min-width: 30%;
}

.min-width-40{
  min-width: 40%;
}

.min-width-50{
  min-width: 50%;
}

.border-bottom-primary{
  border-bottom: 3px solid #ff0000;
}

/* input[type="date"]{
   color: white !important;
} */

input[type="date"]:focus{
    color: #333 !important;
}

div.text-complete .card div{
  padding: 10px;
}

.appBarTransparent{
  background-color: rgba(67, 129, 168,0.5);
}

.appBarSolid{
  background-color: rgba(67, 129, 168);
}

.h0{
  font-size: var(--h0-font-size);
}

.bold{
  font-weight: bold;
}

.cms-title,
.h1{
  font-family: 'Anton', sans-serif;
  font-size: var(--h1-font-size) !important;
}

.title-banner{
  font-family: 'Anton', sans-serif !important;
  color: #333 ;
  font-size: var(--h1-font-size) !important;
  margin: 0 !important;
  padding: 0 !important;
}
.title-banner b{
  color: #000;
}

.h2{
  font-size: var(--h3-font-size);
}

.h4{
  font-size: var(--h4-font-size) !important;
}


.text-white{
  color: #fff;
}

.text-white2{
  color: #dadada;
}

.g-recaptcha{
  width: 100%;
  padding: 10px;
  background-color: var(--color-primary);
  font-size: 20px;
  color: #fff;
  border: 0;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.float-right{
  float: right;
}

.fullWidth{
  width: 100%;
}

.img-texto{
  float: left;
  width: 40%;
  margin-right: 30px;
  border: solid 5px var(--color-primary);
}


.slider_content .slider_item .slider_item_label_center{
  width: 100%;
  position: absolute;
  top: 25%;
  z-index: 100;
  text-align: center;
}

.slider_content .slider_item .slider_item_label{
  max-width: 40%;
  position: absolute;
  font-size: var(--h1-font-size);
  z-index: 100;
  top: 25%;
  left: 10%;
  padding: 0px 20px;
  /*background: #fff;*/
  color: #fff;
  font-weight: 700;
  font-family: 'Raleway';
}

.slider_item .position-relative .content{
  width: 100%;
  position: absolute;
  min-height: 500px;
}


.slider_content .slider_item .slider_item_text{
  position: absolute;
  font-size: var(--h2-font-size);
  z-index: 100;
  top: 110%;
  left: 0%;
  padding: 10px 20px;
  font-family: 'Raleway';
  font-weight: 700;
  max-width: 80%;
  color: #fff;
}

.slider_content .slider_item .slider_item_btn{
  width: 50%;
  position: absolute;
  z-index: 100;
  top: 50%;
  text-align: center;
}

.slider_content .slider_item .slider_item_btn a{

}

.position-relative{
  position: relative;
}

.text-primary{
  background: var(--color-primary);
  padding: 0 40px;
  color: #fff;
}

.projects{
  background: #5ff7d8;
  background: -moz-linear-gradient(0deg,#5ff7d8 0,#8c6cff 80%);
  background: -webkit-linear-gradient(0deg,#5ff7d8 0,#8c6cff 80%);
  background: linear-gradient(0deg,#5ff7d8 0,#8c6cff 80%);

}

div.text-complete{
  /* background-image: url("./assets/images/blob4.svg");
  background-repeat: no-repeat;
  background-position: left; */
}

.bg-cms{
  /* background-image: url("./assets/images/blob-scene-haikei.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

.projects .header{
  height: 100px;
  background-image: url("./assets/images/wave-haikei-projects.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.pwa .separator{
  margin-top: -150px;
  height: 200px;
  background-image: url("./assets/images/wave-pwa.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.pwa .separator2{
  margin-top: -70px;
  height: 240px;
  background-image: url("./assets/images/wave2.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.pwa  .content-primary{
  padding: 10px;
  background-color: #e6007e;
  /*background: linear-gradient( 45deg, #e6007e, #592e76 );*/
}

.website .separator{
  margin-top: -150px;
  height: 200px;
  background-image: url("./assets/images/wave-website.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.website .separator2{
  margin-top: -70px;
  height: 240px;
  background-image: url("./assets/images/wave2-website.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.website  .content-primary{
  padding: 10px;
  background-color: #0099ff;
  /*background: linear-gradient( 45deg, #e6007e, #592e76 );*/
}

.bg-clients{
  width: 100%;
  padding-top: 100%;
  background-image: url("./assets/images/blurry-gradient-haikei.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.img-clients{
  position: absolute;
  z-index: 100;
}

.projects .footer{
  height: 50px;
  background-image: url("./assets/images/wave-haikei-projects-2.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.projects .content{
  min-height: 350px;
  padding: 100px 0;
}

.support{
  background-color: #fff;
  /*margin: 50px 0;*/
  margin-bottom: 0px;
  padding: 20px;
  padding-bottom: 0;
}

.elevation{
  background-color: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.footer{

}
.footer-separator{
  position: relative;
  background-color: var(--color-primary);
  background-image: url("./assets/images/wave-haikei.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: bottom;
}

.footer-separator .img-logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.footer-separator .img-logo img{
  width: 60%;
}

.footer-header{
  /*margin-top: -100px;*/
  margin-bottom: 80px;
  padding-bottom: 10px;
  width: 100%;
  text-align: center;
  /*border-bottom: 1px solid white;*/
}
.footer-header img{
  width: 25%;
}

.footer-logo{
  max-width: 500px;
  margin-bottom: 45px;
}


.footer-body{
  /*margin-top: -80px;*/
  min-height: 180px;
  padding: 20px;
  background-color: var(--color-primary);
  padding-bottom: 100px;
}

.news{
  min-height: 350px;
  padding: 20px;
  background-image: url("./assets/images/layered-waves-haikei3.svg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: bottom;
  padding-bottom: 150px;
}

.text-title{
  font-size: 24px;
}

.border-bottom{
  border-bottom: 2px solid #ddd;
  padding: 5px 0;
  margin-bottom: 10px;
}

.footer-content{
  font-size: 14px;

}
.footer-content div{
  color: white;
  margin-bottom: 5px;
}

.scale{
  transition: transform .2s !important;
}

.scale:hover{
  transform: scale(1.05);
  box-shadow: 0px 5px 3px 0px rgba(0,0,0,0.2),
              0px 5px 3px 0px rgba(0,0,0,0.14),
              0px 5px 3px 0px rgba(0,0,0,0.12);
}

.scale2{
  transition: transform .2s !important;
}

.scale2:hover{
  transform: scale(1.05);
}

.MuiCardHeader-subheader{
  font-size: 11px !important;
  font-weight: bold;
}
.clients-hover{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.9);
  text-align: center;
  padding: 10px;
  opacity: 0;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
  z-index: 10000;
}
.clients-hover:hover{
  opacity: 1;
}
p{
  line-height: 30px;
}
ul{
  padding: 10px;
}
li{
  margin-bottom: 15px;
}
.img-1{
  float: left;
  width: 100px;
  margin-right: 10px;
}
.whatsapp{
  position: fixed;
  right: 10px;
  top: 70px;
  padding: 10px;
}

.item-helps{
  border: solid 2px #f2f2f2;
  padding: 10px;
}

.roboto{
  font-family: 'Raleway';
}

.content-input-color{
  background: #fff;
  border: 1px solid #bbb;
  position: relative;
  border-radius: 4px;
  height: 54px;
  cursor: pointer;
  vertical-align: top;
  margin-bottom: 8px;
}

.content-input-color input{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: absolute !important;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
}

.content-input-color svg{
  position: absolute;
  top: 25%;
  left: 40%;
}

.color-content{
  padding: 10px 3px;
}

.color-content input{

}
.color-content label{
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

.video{
  background-color: #c2c2c2;
  padding: 10px 0;
  z-index: 1000;
}

.notify-title{
  font-size: 11px !important;
  font-weight: bold !important;
}
.notify-message{
  font-size: 12px !important;
}
.notify-transmitter{
  font-size: 10px !important;
  font-weight: bold !important;
}

.max-height-300{
  height: 80px;
  overflow: hidden;
}

@media(max-width:770px){

  .cta-title{
    font-size: var(--h1-font-size);
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 22px !important;
    line-height: 49px;
    max-width: 90%;
  }

  .title-main-secondary{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 22px !important;
    line-height: 38px;
    text-align: center;
    padding:0;
  }

  .title-main{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 34px !important;
    line-height: 49px;
    text-align: center;
  }


  .MuiBreadcrumbs-li,
  .css-15vhvyh-MuiTypography-root{
    font-size: 12px !important;
  }

  .content-input-color{
    width: 97%;
  }

  .row{
    display: flex;
    flex-wrap: wrap;
  }

  .row .col{
    text-align: justify;
  }

  .row .col-4{
    width: 100%;
  }

  .row .col-6{
    width: 100% !important;
  }

  .h1{
    font-size: var(--h1-font-size-xs);
    text-transform: uppercase;
  }

  .h2{
    font-size: var(--h3-font-size-xs);
    text-transform: uppercase;
  }

  .h7{
    font-size: 12px !important;
    text-transform: uppercase;
  }

  .h7 svg{
    width: 0.5em;
    height: 0.5em;
    margin-right: 3px;
  }

  .slider_content{
    max-height: 100%;
  }

  .slider_content .slider_item{
    max-height: var(--min-height-slider-xs);
    overflow: hidden;
    position: relative;
  }


  .slider_content .slider_item .slider_item_label{
    left: 0;
    top: 10%;
    width: 100%;
    max-width: 100%;
    font-size: var(--h1-font-size-xs);
    padding: 0px 20px;
    background: #fff;
    font-family: 'Anton', sans-serif;
    padding: 0 4px ;
  }


  .slider_content .slider_item .slider_item_text{
    position: absolute;
    font-size: var(--h2-font-size-xs);
    z-index: 100;
    top: 96%;
    max-width: 100%;
    left: 0%;
    color: #fff;
    padding: 0 4px ;
  }

  .slider_item .position-relative .content{
    width: 100%;
    position: relative;
    min-height: 76px;
  }

  .footer-logo{
    max-width: 100%;
    margin-bottom: 45px;
  }

  .slider_content .slider_content{
    height: 100px;
  }

  .slider_content .slider_item{
    min-height: 100px;
    overflow: hidden;
    position: relative;
  }

  .cms-title{
    display: none;
  }

  p{
    line-height: 30px;
  }

  ul{
    padding: 10px;
  }

  li{
    margin-bottom: 15px;
  }

  .text-primary{
    padding: 0;
    color: #fff;
  }

  .text-center{
    text-align: center;
  }

  .h1{
    font-family: 'Anton', sans-serif;
    font-size: var(--h1-font-size-xs) !important;
  }

  .img-texto{
    float: left;
    width: 96%;
    margin-right: 30px;
    border: solid 5px var(--color-primary);
  }

  .whatsapp{
    position: fixed;
    right: 0px;
    top: 70px;
    padding: 0px;
  }
  .whatsapp a{
    padding: 0;
    min-width: 10px;
  }
  .whatsapp a svg{
    padding: 0 5px ;
    margin: 0;
    font-size: 30px;
  }

}
